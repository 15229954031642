<template>
	<div class="outer-wrapper mobile-only">
		
		<div id="top-nav">
			<router-link :to="{ name: 'Review', params: { trip_id: this.tripID, trip_item_id: this.tripItemID }}">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
		</div>

		<div class="stripe-section">
			<div class="inner-wrapper">
				<div class="heading4">Payment Details</div>
			</div>
			<div>
				<div class="price summary-table" v-if="!loading && !error">
					<div class="table-row big">
						<div class="name">Total</div>
						<div class="data">£{{ frontendTotal }}</div>
					</div>
				</div>
				<div class="inner-wrapper">

					<!-- <div class="summary" v-if="totalPrice > 0">

						<div class="pricing-table" v-if="trip.tripItems[tripItemID].travellerTypePricing">
							<div v-for="(value, key) in trip.tripItems[tripItemID].travellerTypePricing" :key="key" class="type">
								<img v-if="key == 'Adult' || key == 'Child' || key == 'Infant'" :src="getImage('travellers/' + key.toLowerCase() + '-icon')" class="pax-icon" :alt="type + 'Icon'" />
								<img v-else src="@/assets/travellers/adult-icon.png" class="pax-icon" :alt="type + 'Icon'" />
								{{ key }}<span v-if="value.minAge && value.maxAge"> (ages {{ value.minAge }} - {{ value.maxAge }})</span>: £{{ value.price }} x {{ trip.tripItems[tripItemID].totalTravellers[key] }}
								<span class="price">£{{ value.price * trip.tripItems[tripItemID].totalTravellers[key] }}</span>
							</div>
							<div class="total" v-if="tripItemBookingValidity.valid && totalPrice == trip.tripItems[tripItemID].price">
								Total Amount
								<span class="total-value">£{{ trip.tripItems[tripItemID].price }}</span>
							</div>
						</div>
					</div> -->

					<h2 class="card-heading" v-if="!loading && !error">Card Details</h2>
					<form id="payment-form">
						<!-- <Loader :loading="loading" :error="error" @refresh="checkForIssues()" refreshText="Check for issues"/> -->
						<!-- Instead of loading symbol, app is in demonstration mode, show message -->
						<p class="error">Sorry! The app is in demonstration mode and we are not currently accepting any bookings. <br>Contact <a href="mailto:contact@purpose.travel">contact@purpose.travel</a> to enquire.</p>

						<!-- <button class="button" v-if="!loading && error" @click.prevent="checkForIssues()">Check for issues</button> -->
						<div v-if="!loading && error" style="margin-top: 20px;">
							It looks like something has gone wrong with this booking. Please try again using the button above, or if the issue persists, contact support using the booking reference number {{ tripID }}<span v-if="tripItemID">_{{ tripItemID }}</span> at <span v-html="supportEmailLink()"></span>
						</div>

						<div class="payment-element" id="payment-element">
							<!-- Elements will create form elements here -->
						</div>
						
						<div id="error-message" :class="{ show: !loading }">
							<!-- Display error message to your customers here -->
						</div>

						<button id="submit" class="button large" v-if="!loading && !error">Pay now</button>
					</form>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
// This component is loaded with tripItemID as OPTIONAL. If it is provided, payment form is created for THAT ITEM ALONE. If not provided, payment form is created for the entire trip.

import Loader from '@/components/Loader.vue';
import { paymentService } from '@/services/paymentService.js';
import { tripService } from '@/services/tripService.js';
import { mapState, mapActions, mapGetters } from 'vuex'
import helpers from '@/helpers/helpers.js';
import constants from '@/helpers/constants.js';
import track from "@/helpers/track.js";
import router from '@/router';

export default {
	data() {
		return {
			tripID: this.$route.params.trip_id,
			tripItemID: this.$route.params.trip_item_id ? this.$route.params.trip_item_id : null,
			tripItems: null,

			totalPrice: 0.00,

			loading: true,
			error: "",

			// Stripe objects
			stripe: null,
			elements: null,
		}
	},
	computed: {
		...mapState({
			trip: state => state.trip,
		}),
		...mapGetters({
			tripItemBookingValidity: "trip/tripItemBookingValidity",
			tripItemsCategorized: 'trip/tripItemsCategorized',
		}),
		returnUrl() {
			if(this.tripItemID) {
				return window.location.origin + '/checkout/confirmation/' + this.tripID + '/' + this.tripItemID;
			} else {
				return window.location.origin + '/checkout/confirmation/' + this.tripID;
			}
		},
		frontendTotal() {
			return this.tripItems.map(i => {
				return Math.round(Number(i.price) * 100); // converting to cents
			}).reduce((a, b) => a + b, 0) / 100; // summing up and converting back to dollars
		}

	},
	components: {
		Loader,
	},
	methods: {
		supportEmailLink: (linkText) => constants.supportEmailLink(linkText),
		...mapActions({
			initTrip: "trip/initTrip",
		}),
		async checkForIssues() { // Refreshes the trip data and redirects to the trip page. used when there is an unknown error so that the user can check for any new trip alerts etc
			this.initTrip({ tripID: this.tripID, force: true });
			router.push({ name: 'Trip', params: { trip_id: this.tripID } });
		},
		getImage: (img) => helpers.getImage(img),
		createPaymentIntent() {
			// Determine data for payment intent creation.
			let tripItemsData = {}; // All data in the trip to be booked
			let specificTripItemData = {}; // Specific trip item ID data to override if provided.

			for(let id in this.tripItems) {
				let item = this.tripItems[id];
				let data = {
					id: item.id,
					amount: item.price
				}

				if(item.bookingStatus.code == "not_booked") {
					tripItemsData[`${id}`] = data;

					if(this.tripItemID) { // If specific trip item provided, only return data for that specific trip.
						if(this.tripItemID == item.id) {
							specificTripItemData[`${id}`] = data;
						}
					}
				}
			}

			let paymentData = null;
			if(this.tripItemID) {
				paymentData = specificTripItemData; // only include specific trip item.
			} else {
				paymentData = tripItemsData; // Otherwise all trip non-booked items
			}

			return paymentService.createPaymentIntent(this.tripID, paymentData);
		},
		createPaymentForm(paymentIntent) {
			return false; // App is in demonstration mode
			
			this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY); // TODO Make this config based.
			const options = {
				clientSecret: paymentIntent,
				// Fully customizable with appearance API.
				appearance: {
					// theme: 'flat',
					variables: {
						colorPrimary: '#06D6A0',
						colorBackground: '#ffffff',
						colorText: '#000000',
						fontSizeBase: '16px',
						fontSizeSm: '12px',
						colorDanger: '#df1b41',
						// fontFamily: '"Inter", sans-serif',
						spacingUnit: '4px',
						borderRadius: '10px',
						spacingGridRow: '16px'
					},
					rules: {
						'.Input': {
							paddingTop: '18px',
							paddingBottom: '18px',
						},
						'.Label': {
							color: '#898A8D',
							marginBottom: '6px',
						}
					}
				},
			};

			// Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
			this.elements = this.stripe.elements(options);
			// Create and mount the Payment Element
			const paymentElement = this.elements.create('payment');
			paymentElement.on('ready', () => {
				this.loading = false;
			});
			paymentElement.mount('#payment-element');
		
			
		},
		async init() {
			this.loading = true;
					
			await this.initTrip({ tripID: this.tripID, force: true }); // Force a refresh of the trip data, in case the refresh button was used to trigger this

			// If TripItemID is provided, then only book that specific trip item
			if(this.tripItemID) { // If specific trip item provided, only return data for that specific trip.
				this.tripItems = [];
				this.tripItems.push(this.tripItemsCategorized.bookable.items.find(item => item.id == this.tripItemID));
			} else {
				// All bookable items
				this.tripItems = this.tripItemsCategorized.bookable.items;
			}

			// Create payment intent
			let paymentIntentResponse = false;
			try {
				paymentIntentResponse = await this.createPaymentIntent()
			} catch(error) {
				this.$rollbar.error(error);
				console.error(error);
				this.error = error;
				this.loading = false;
			}
			
			if(paymentIntentResponse) {
				if(paymentIntentResponse.data.data.price) {
					this.totalPrice = paymentIntentResponse.data.data.price;
				}
				if(paymentIntentResponse.data.data.client_secret) {
					// Final check that the backend and frontend prices match up
					console.error(this.tripItems);
					console.error("Frontend: " + this.frontendTotal);
					console.error("Backend: " + this.totalPrice);
					if(this.frontendTotal == this.totalPrice) {
						// Create form with paymentIntent clientSecret
						this.createPaymentForm(paymentIntentResponse.data.data.client_secret);
					} else {
						this.error = "There is a price discrepency. Please try again or contact support.";
						this.loading = false;
					}
				}

				// Add listener for submit and process payment
				const form = document.getElementById('payment-form');
				form.addEventListener('submit', async (event) => {
					this.loading = true;
					event.preventDefault();
					try {
						track.ecommerce("add_payment_info", { // This tracks before the stripe response, so it fires if card details are denied (intended)
							currency: "GBP",
							value: this.totalPrice,
							payment_type: "Stripe",
						}, [this.trip.tripItems[this.tripItemID]]);
					} catch(e) {
						console.error(e);
						this.$rollbar.error("Tracking: " + e);
					}
					const {error} = await this.stripe.confirmPayment({
						//`Elements` instance that was used to create the Payment Element
						elements: this.elements,
						confirmParams: {
							return_url: this.returnUrl,
						},
					});
					this.loading = false;
					if (error) {
						// This point will only be reached if there is an immediate error when
						// confirming the payment. Show error to your customer (for example, payment
						// details incomplete)
						const messageContainer = document.querySelector('#error-message');
						messageContainer.textContent = error.message;
					} else {
						// NO CODE WILL FIRE HERE - page will already change.
						// Your customer will be redirected to your `return_url`. For some payment
						// methods like iDEAL, your customer will be redirected to an intermediate
						// site first to authorize the payment, then redirected to the `return_url`.
					}
				});
			}
		}
	},
	async created () {
		this.init();
	},
	mounted () {
		// // Add stripe script to head (before component loaded)
		let externalScript = document.createElement('script')
		externalScript.setAttribute('src', 'https://js.stripe.com/v3/')
		document.head.appendChild(externalScript);
	},
}
</script>

<style scoped>
	.card-heading {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		color: #118AB2;
		margin-top: 25px;
		margin-bottom: 15px;
	}
	#payment-form .button {
		margin: 0 auto;
		margin-top: 35px;
		display: block;
		margin-bottom: 5px;
	}
	#error-message {
		margin-top: 10px;
		color: red;
	}

	.payment-element {

	}

</style>